<template>
  <b-container>
    <b-row class="white-background">
      <b-col
        class="top-info-left px-0"
      >
        <div class="">
          <b-card class="m-0">
            <b-card-text>
              <div class="orn-form-header d-flex justify-content-between mb-75">
                <div class="d-form-logo_section" />
                <div class="orm-form-form_no_section text-right">
                  <h5>
                    {{ serviceFormView.formNumber || 'NV-SG-NVC-FS01' }}
                  </h5>
                  <h5>
                    {{ serviceFormView.revision || 'REV: 1' }}
                  </h5>
                </div>
              </div>
              <div class="b-form-section-title my-2">
                <h3
                  class="mb-2 b-form-section-title"
                >
                  SERVICE FORM <span>礼仪服务预约表格</span>
                </h3>
              </div>
              <b-table-simple
                borderless
                responsive
                class="particulars_of_deceased d-none d-md-block ps-simple-table"
              >
                <b-tbody>
                  <b-tr>
                    <b-th colspan="2">
                      Applicant Details
                    </b-th>
                    <b-th colspan="2">
                      Particulars of Deceased
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-td width="230px">
                      Applicant 报名者:
                    </b-td>
                    <b-td width="230px">
                      <span class="text-primary">{{ serviceForm.customerName || '-' }}</span>
                    </b-td>
                    <b-td width="230px">
                      Niche No. 福位编号:
                    </b-td>
                    <b-td>
                      <span class="text-primary">{{ serviceForm.deceasedNiche || '-' }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td width="230px">
                      Contact No. 联络号码:
                    </b-td>
                    <b-td width="230px">
                      <span class="text-primary">(65) {{ serviceForm.customerContact || '-' }}</span>
                    </b-td>
                    <b-td width="230px">
                      Pedestal No. 牌位编号:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedPedestal || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td width="230px">
                      Email 电邮:
                    </b-td>
                    <b-td width="230px">
                      <span class="text-primary">{{ serviceForm.customerEmail || '-' }}</span>
                    </b-td>
                    <b-td width="230px">
                      Anling Lot No. 安灵编号:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedAngling || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td />
                    <b-td />
                    <b-td width="230px">
                      Name of Deceased 先人名讳:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedName || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td />
                    <b-td />
                    <b-td width="230px">
                      Departed Date 逝世日期:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.departedDate ? dateFormat(serviceForm.departedDate) : '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td />
                    <b-td />
                    <b-td width="230px">
                      Age 年龄:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedAge || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td />
                    <b-td />
                    <b-td width="230px">
                      Gender 性别:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedGender || '-' }}</span></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>

              <b-table-simple
                borderless
                responsive
                class="d-block d-md-none ps-simple-table"
              >
                <b-tbody>
                  <b-tr>
                    <b-th>
                      Applicant Details
                    </b-th>
                    <b-th />
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Applicant 报名者:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.customerName || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Contact No. 联络号码:
                    </b-td>
                    <b-td><span class="text-primary">(65) {{ serviceForm.customerContact }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Email 电邮:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.customerEmail || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>
                      Particulars of Deceased
                    </b-th>
                    <b-th />
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Niche No. 福位编号:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedNiche || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Pedestal No. 牌位编号:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedPedestal || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Anling Lot No. 安灵编号:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedAngling || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Name of Deceased 先人名讳:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedName || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Departed Date 逝世日期:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.departedDate ? dateFormat(serviceForm.departedDate) : '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Age 年龄:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedAge || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Gender 性别:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedGender || '-' }}</span></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>

              <div class="ps-service-type-block">
                <h4 class="ps-service-type-title">
                  Service Type & Descriptions 服务类别和项目说明
                </h4>
                <div
                  v-for="(val, ind) in groupedCategory"
                  :key="ind"
                >
                  <h6 class="ps-service-type-name">
                    {{ val[0].workFlow.category.name }}
                  </h6>
                  <div
                    v-for="(request, requestIndex) in val"
                    :key="requestIndex"
                    class="ps-service-des-content-wrapper"
                  >
                    <div class="ps-service-des-block">
                      <div class="ps-service-des-checkbox-block">
                        <b-form-checkbox
                          v-model="checked"
                        >
                          <p>
                            {{ request.workFlow.name }}
                          </p>
                        </b-form-checkbox>
                      </div>
                      <div
                        v-if="!specialWorkflows.includes(request.workFlow._id)"
                        class="ps-service-des-content-block"
                      >
                        <b-table-simple
                          borderless
                          responsive
                          class="ps-simple-table"
                        >
                          <b-tbody>
                            <b-tr>
                              <b-td width="45%">
                                Location 地点:
                              </b-td>
                              <b-td><span class="text-primary">{{ request.location || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td width="45%">
                                Service Date and Time 预定服务日期与时间:
                              </b-td>
                              <b-td>
                                <span
                                  v-if="request.date"
                                  class="text-primary"
                                >
                                  {{ dateFormat(request.date) }}, {{ timeFormatFromDateString(request.date) }}
                                </span>
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td width="45%">
                                Related File No. 相关文件号码:
                              </b-td>
                              <b-td><span class="text-primary">{{ request.fileNo || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr class="border-bottom">
                              <b-td width="45%">
                                Remark 备注:
                              </b-td>
                              <b-td><span class="text-primary">{{ request.remarks || '-' }}</span></b-td>
                            </b-tr>
                          </b-tbody>
                          <b-tbody v-if="specialSDWorkflows.includes(request.workFlow._id)">
                            <b-tr
                              v-if="request.blessingQuantity"
                              :class="{ 'd-flex flex-column' : isMobile() }"
                            >
                              <b-td
                                width="50%"
                                style="vertical-align: top; padding: 5px 0px !important;"
                              >
                                福
                              </b-td>
                              <b-td>
                                <span class="text-primary">x</span>
                                <span
                                  class="green-text"
                                >
                                  {{ request.blessingQuantity }}
                                </span>
                              </b-td>
                            </b-tr>
                            <b-tr
                              v-if="request.blessingQuantity"
                              :class="{ 'd-flex flex-column' : isMobile() }"
                            >
                              <b-td
                                width="50%"
                                style="vertical-align: top; padding: 5px 0px !important;"
                              >
                                Remarks
                              </b-td>
                              <b-td>
                                <span class="green-text">{{ request.blessingRemarks || '-' }}</span>
                              </b-td>
                            </b-tr>

                            <b-tr
                              v-if="request.lifeQuantity"
                              :class="{ 'd-flex flex-column' : isMobile() }"
                            >
                              <b-td
                                width="50%"
                                style="vertical-align: top; padding: 5px 0px !important;"
                              >
                                壽
                              </b-td>
                              <b-td>
                                <span class="text-primary">x</span>
                                <span
                                  class="green-text"
                                >
                                  {{ request.lifeQuantity }}
                                </span>
                              </b-td>
                            </b-tr>
                            <b-tr
                              v-if="request.lifeQuantity"
                              :class="{ 'd-flex flex-column' : isMobile() }"
                            >
                              <b-td
                                width="50%"
                                style="vertical-align: top; padding: 5px 0px !important;"
                              >
                                Remarks
                              </b-td>
                              <b-td>
                                <span class="green-text">{{ request.lifeRemarks || '-' }}</span>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                          <b-tbody v-if="!specialSDWorkflows.includes(request.workFlow._id) && (request.showServiceDescription && request.showServiceDescription == true)">
                            <b-tr
                              v-for="(opt, key) in request.serviceDescriptionValues"
                              :key="key"
                            >
                              <b-td
                                v-if="(opt.children && opt.children.length) || opt.value"
                                width="45%"
                                style="vertical-align: top; padding: 5px 0px !important;"
                              >
                                {{ opt.key }}
                                <div>
                                  <span
                                    style="font-style: normal; font-weight: 400; font-size: 14px;"
                                  >
                                    {{ opt.text }}
                                  </span>
                                </div>
                              </b-td>
                              <b-td v-if="(opt.children && opt.children.length) || opt.value">
                                <div v-if="opt.children">
                                  <div
                                    v-for="(child, indx) in opt.children"
                                    :key="indx"
                                  >
                                    <span
                                      v-if="Array.isArray(child.value)"
                                      class="d-flex"
                                    >
                                      <span
                                        class="mr-50"
                                      >
                                        {{ child.key }}
                                      </span>
                                      <div v-if="child.value.length">
                                        <span
                                          v-for="(option, index) in child.value"
                                          :key="index"
                                        >
                                          {{ option }} <span v-if="index + 1 != child.value.length">, </span>
                                        </span>
                                      </div>
                                      <div v-else>
                                        <span>-</span>
                                      </div>
                                    </span>
                                    <span
                                      v-else
                                      class="d-flex"
                                    >
                                      <span
                                        class="mr-50"
                                      >
                                        {{ child.key }}
                                      </span>
                                      <div v-if="child.value">
                                        <span
                                          v-if="(child.type && ['increment', 'short-answer', 'long-answer'].includes(child.type))"
                                          class="green-text"
                                        >
                                          {{ child.value }}
                                        </span>
                                        <span v-else>{{ child.value }}</span>
                                      </div>
                                      <div v-else>
                                        <span>{{ child.type == 'title-and-text' ? '' : '-' }}</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div v-else>
                                  <span
                                    v-if="Array.isArray(opt.value)"
                                    class="d-flex"
                                  >
                                    <div v-if="opt.value.length">
                                      <span
                                        v-for="(option, index) in opt.value"
                                        :key="index"
                                      >
                                        {{ option }} <span v-if="index + 1 != opt.value.length">, </span>
                                      </span>
                                    </div>
                                    <div v-else>
                                      <span>-</span>
                                    </div>
                                  </span>
                                  <span
                                    v-else
                                    class="d-flex"
                                  >
                                    <div v-if="opt.value">
                                      <span
                                        v-if="(opt.type && ['increment', 'short-answer', 'long-answer'].includes(opt.type))"
                                        class="green-text"
                                      >
                                        {{ opt.value }}
                                      </span>
                                      <span v-else>{{ opt.value }}</span>
                                    </div>
                                    <div v-else>
                                      <span>{{ opt.type == 'title-and-text' ? '' : '-' }}</span>
                                    </div>
                                  </span>
                                </div>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="border-bottom" />
                <b-row class="m-0">
                  <b-col
                    lg="4"
                    class="border-right p-0"
                  >
                    <div class="ps-notice-left-section">
                      <b-row class="m-0">
                        <b-col
                          lg="12"
                          class="p-0"
                        >
                          <p>Agent Name 代理姓名:</p>
                          <p class="ps-notice-field-value">
                            {{ serviceForm.agentName || '-' }}
                          </p>
                        </b-col>
                        <b-col
                          lg="12"
                          class="p-0"
                        >
                          <p>Agent Code 代理编号:</p>
                          <p class="ps-notice-field-value">
                            {{ serviceForm.agentCode || '-' }}
                          </p>
                        </b-col>
                        <b-col
                          lg="12"
                          class="p-0"
                        >
                          <p>Agent Mobile Number 代理联络号码:</p>
                          <p class="ps-notice-field-value">
                            {{ serviceForm.agentContact ? `(65)${serviceForm.agentContact}` : '-' }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col lg="8 p-0">
                    <div class="ps-notice-right-section">
                      <h5>Notice 备注</h5>
                      <ul>
                        <li>
                          Payment and Reservation is to be done 3 days in advance.
                          <br>
                          <span>预购是指三天前完成订货与缴付金额。</span>
                        </li>
                        <li>
                          Rental of premises is a must for chanting duration more than an hour.
                          <br>
                          <span>诵经时间一小时以上必需租用场地</span>
                        </li>
                        <li>
                          Rental of burning cage is required if there is a large amount of paper offerings. (eg. 8’ x 16’ of paper house or 12 offering chests and above.)
                          <br>
                          <span>若焚烧大量纸钱，衣箱（12个衣箱或超过）或纸屋（限制纸屋面积8尺 x 16尺）必需提出申请租用化宝架。</span>
                        </li>
                        <li>
                          Cheque shall be made payable to <b>“Mount Prajna Ltd”</b>
                          <br>
                          <span>支票上请注明交于</span>
                          <b>“Mount Prajna Ltd”</b>
                        </li>
                        <li>
                          All prices are inclusive GST.
                          <br>
                          <span>以上价格已含消费税。</span>
                        </li>
                      </ul>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <div class="authorized-signature ps-authorized-signature mt-3">
                <b-row ref="signDiv">
                  <b-col md="6">
                    <p>
                      Purchaser / Authorized Representative Signatory & Date
                      <br>
                      <span>购买者/受委托领瓮人签名和日期</span>
                    </p>
                    <div
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="form-signature-box signed"
                    >
                      <div class="form-signature-content">
                        <span
                          v-if="!serviceForm.customerSign"
                          class="form-signature-text"
                        />
                        <b-img
                          v-else
                          :src="serviceForm.customerSign"
                          alt="authorized-sign"
                        />
                      </div>
                    </div>
                    <div class="signature-date-block ps-signature-date-block">
                      <p>Name 姓名: {{ serviceForm.customerName }}</p>
                      <p v-if="serviceForm.customerSignDate">
                        Date 日期: {{ dateFormatWithTime(serviceForm.customerSignDate) }}
                      </p>
                      <p v-else>
                        Date 日期:
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <div class="border mt-4 ps-bottom-box">
                <h5 class="border-bottom text-center text-uppercase">
                  For office use 公务用栏
                </h5>
                <b-row>
                  <b-col
                    md="6"
                    class="px-2 py-2 ps-authorized-signature"
                  >
                    <p>Service Staff 服务职员:</p>
                    <div
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="form-signature-box signed"
                    >
                      <div class="form-signature-content">
                        <span
                          v-if="!serviceForm.staffSign"
                          class="form-signature-text"
                        />
                        <b-img
                          v-else
                          :src="serviceForm.staffSign"
                          alt="authorized-sign"
                        />
                      </div>
                    </div>
                    <div class="signature-date-block ps-signature-date-block">
                      <p>Name 姓名: {{ serviceForm.signedByStaffName }}</p>
                      <p v-if="serviceForm.staffSignDate">
                        Date 日期: {{ dateFormatWithTime(serviceForm.staffSignDate) }}
                      </p>
                      <p v-else>
                        Date 日期:
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BCard, BRow, BCol, BTableSimple, BTbody, BTr, BTh, BTd, VBToggle, VBTooltip, BCardText, BImg, BContainer,
  BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store/index'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCardText,
    BImg,
    BContainer,
    BFormCheckbox,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      serviceForm: {},
      serviceFormID: '',
      stringID: '',
      status: 'pending',
      statusValidation: false,
      statusError: 'Valid status is required',
      dutyOfficer: '',
      dutyOfficerValidation: false,
      showCustomFormSection: false,
      dutyOfficerError: 'Valid duty officer is required',
      dutyOfficerOptions: [],
      serviceFormActivityLogs: [],
      statusDataOptions: [
        { title: 'Completed', code: 'completed' },
        { title: 'Pending', code: 'pending' },
      ],
      requestTypeOptions: [],
      assigneeOptions: [],
      smsText: '',
      agentContact: '',
      customerContact: '',
      smsTextValidation: false,
      smsTextError: '',
      description: '',
      attachmentKey: '',
      shallShowEmailComposeModal: false,
      required,
      emailToOptions: [],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
      currentTabTitle: 'Service Form',
      activeTab: 0,
      checked: true,
      serviceRequests: [],
      serviceFormView: {},
      venueField: '',
      titleFields: '',
      specialWorkflows: [],
      specialSDWorkflows: [],
      specialServiceDescription: [],
      groupedCategory: [],
      tabActive: 'email',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Compose message',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Compose message',
      },
      customerPadOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      staffPadOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.staffSignaturePad.resizeCanvas() },
      },
    }
  },
  mounted() {
    this.fetchServiceForm()
  },
  methods: {
    fetchServiceForm() {
      this.$http.get(`front-end/service-forms/${this.$route.params.id}/show/form`)
        .then(response => {
          this.serviceForm = response.data.data || {}
          this.serviceRequests = response.data.serviceRequests || {}
          this.serviceFormView = response.data.serviceFormView || {}
          this.venueField = response.data.venueField ?? ''
          this.titleFields = response.data.titleFields ?? ''
          this.specialWorkflows = response.data.specialWorkflows.split(',')
          this.specialSDWorkflows = response.data.specialSDWorkflows.split(',')
          this.specialServiceDescription = response.data.specialServiceDescription.split(',')
          document.title = `${response.data.data.stringID} - ${response.data.data.customerName} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`

          this.setData()

          // let fieldCount = 0
          // if (this.serviceForm.serviceDescription.length) {
          //   this.serviceForm.serviceDescription.forEach(arrayItem => {
          //     if (!['file', 'title-and-text'].includes(arrayItem.type)) {
          //       // eslint-disable-next-line no-plusplus
          //       fieldCount++
          //     }
          //   })
          // }

          // if (fieldCount > 0) {
          //   this.showCustomFormSection = true
          // }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setData() {
      this.serviceFormID = this.serviceForm._id
      this.status = this.serviceForm.status
      this.stringID = this.serviceForm.stringID
      this.dutyOfficer = this.serviceForm.dutyOfficer
      this.customerContact = this.serviceForm.customerContact
      this.customerMobileVal = this.serviceForm.customerContact
      this.customerEmailVal = this.serviceForm.customerEmail
      this.agentContact = this.serviceForm.agentContact
      const groupByCategory = this.serviceRequests.reduce((group, request) => {
        const category = request.workFlow.category._id
        // eslint-disable-next-line no-param-reassign
        group[category] = group[category] ?? []
        group[category].push(request)
        return group
      }, {})

      this.groupedCategory = groupByCategory
    },
  },
}
</script>

<style>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
  .timeline-variant-purple .timeline-item-point {
    background-color: #7367F0 !important;
  }
  @media print {
    div#app.semi-dark-layout {
      background: #fff !important;
    }
    .white-background {
      background: #fff !important;
    }
  }
</style>
<style lang="scss" scoped>
    .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
      border-right-width: 1px !important;
    }
    .inlineElement {
      display: flex;
    }
    .clickableText {
      cursor: pointer;
      color: #104D9D;
    }
    ul.nav.wrap-border.save-nav {
      position: inherit;
      left: 0;
  }
  .service-request-card {
      margin: 40px 0 40px !important;
  }
  .sd-step-wrapper {
      padding: 40px 0;
  }
  .sd-step-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .sd-step-opacity {
      opacity: 0.3;
      pointer-events: none;
  }
  .sd-full-opacity {
    opacity: 1;
    cursor: pointer;
  }
  .sd-service-single-block{
      background: #E0E8F2;
      border-radius: 6px;
      padding: 20px;
      margin: 10px 0;
  }
  .sd-option-button{
      display: block;
  }
  .sd-option-button .btn-outline-primary:hover:not(.disabled):not(:disabled) {
      background-color: #104D9D;
      color: #FFFFFF;
  }
  .sd-option-button .btn-outline-primary:not(:disabled):not(.disabled).active, .sd-option-button .btn-outline-primary:not(:disabled):not(.disabled):focus {
        background-color: #104D9D;
        color: #FFFFFF;
  }

  .sd-service-description-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 0;
  }

  .sd-single-service-description{
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;
      background: #F5F8FB;
      border-radius: 6px;
      margin: 5px 0;
      p{
          margin-bottom: 0;
          margin: auto 0;
      }
      .increment-decrement-block{
        margin: 0;
      }
  }
  .ps-avatar-bg{
      background: rgba(255, 255, 255, 0.3);
  }

  .ps-cd-header-text-section{
        margin-left: 20px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        margin-top: -5px;
        p{
          margin-bottom: 0;
          color: #FFFFFF;
          font-size: 12px;
          line-height: 23px;
          letter-spacing: 0.6px;
          text-transform: uppercase;
        }
        h5{
          margin-bottom: 0;
          font-size: 18px;
          line-height: 22px;
          color: #FFFFFF;
        }
  }
  .ps-main-content-wrapper{
        background: #9FB8D8;
        padding: 15px;
        position: relative;
  }
  .ps-tooltip-card{
      position: absolute;
      left: -33%;
      bottom: 72.7%;
  }
  .ps-tooltip-card .card .card-body{
      padding: 0 !important;
  }

  .ps-tooltip-card .card .card-body .card-text{
      padding: 17px;
      p{
      margin-bottom: 0;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      }
  }
  .ps-tooltip-card .card .card-body .card-text .ps-tooltip-button{
        background: rgba(255, 159, 67, 0.12) !important;
        border: none !important;
        color: #FF9F43 !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.366667px;
        padding: 8px 19px;
        margin-top: 20px !important;
      }
  .ps-tooltip-card .card .card-body .card-header{
      padding: 7px 16px;
      p{
        margin-bottom: 0;
        font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: #FFFFFF;
      }
  }
  .ps-main-content-wrapper .card .card-body{
      padding: 20px;
  }
  .ps-simple-table .table th, .ps-simple-table .table td {
      padding: 3px 0 !important;
  }
  .ps-service-type-block{
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #CBCBCB;
    .ps-service-type-title{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      background: #4B4B4B;
      padding: 10px 20px;
      margin: 0;
    }
    .ps-service-type-name{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      background: #D2DCEA;
      padding: 6px 20px;
    }
  }

  .ps-service-des-block{
      padding: 10px 20px;
  }
  .ps-service-des-checkbox-block{
      p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          margin: 0;
      }
    }

  .ps-service-des-content-block{
      padding: 0px 0px 0px 28px;
  }

  .ps-service-des-bg-block{
      background: rgba(186, 191, 199, 0.12);
      padding: 10px 20px;
      margin-bottom: 10px;
  }
  .ps-notice-left-section{
        padding: 20px;
        p{
          font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 15px;
        }
        .ps-notice-field-value{
          color: #104D9D !important;
          border-bottom: 1px solid #000000;
        }
  }
  .ps-notice-right-section{
        padding: 10px;
        font-family: 'Montserrat';
        font-style: normal;
        h5{
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #000000;
        }
        ul{
          padding-left: 20px;
          list-style: disc;
        }
        ul > li{
            color: #000000;
            font-size: 14px;
            line-height: 18px;
            padding: 3px 0;
        }

  }
  .ps-authorized-signature{
      font-family: 'Montserrat';
      font-style: normal;
      p{
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 10px;
        color: #000000;
      }
  }
    .ps-authorized-signature .ps-signature-date-block p{
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 0;
        color: #000000;
    }
    .ps-bottom-box{
      h5{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        padding: 8px;
      }
    }
  .page-content hr {
    margin: 20px 0;
    border-color: #D2DCEA;
  }
  </style>

  <style lang="scss">
  @import "~@core/scss/base/pages/app-email.scss";
  </style>
